<template>
  <div>

    <div class="tw-mt-4">

                <h2 class="tw-mb-4 tw-font-semibold tw-text-2xl">{{$t('vendor.toc')}}</h2>
                <h4 class="tw-mb-2">
                  Please upload your terms and conditions as well as any other rules or policies that apply to Customers who book your Vendor Offerings as they are required to use our Platform. If you need help preparing this information, please use an appropriate third-party website or contact your attorney. 
                  For more information regarding Mango & Lola's Terms of Use, please <router-link href="_blank" class="tw-text-black tw-font-semibold" :to="{ name: 'terms.and.conditions'}">Click Here</router-link>.
                </h4>
                <v-form ref="tos-form" @submit.prevent="onSubmit()">
                  <div class="tw-mt-6">
                    <ml-error-msg :msg="errors['terms']" />                
                    <editor
                        id="terms" name="terms"
                        v-model="tos"
                        :init="{
                              height: 500,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar:
                                'undo redo | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help'
                        }"
                    />
                    <ml-error-msg :msg="errors['terms']" />
                  </div>
                  <div class="tw-mt-6">
                    <button type="submit"
                            class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                      Save
                    </button>   
                  </div>               
                </v-form>
     
    </div>


  </div>
</template>


<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';
import i18n from '@/i18n'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  components: {
    Editor,MlErrorMsg
  },
  metaInfo() {
    return {
      title: i18n.t('offers'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendor/offers'),
        },
      ]
    }
  },
  data() {
    return {
      tos: '',
      loading: false,
      valid: true,
      errors: []
    }
  },
  mounted() {
    this.getTos();
  },
  methods: {
    onSubmit(){
      if(this.validateForm()) this.saveTos();
    }, 
    validateForm() {
      this.errors = [];
      let label = '';

        if (this.tos.length <= 100) {
          label = "Minimum 100 characters required.";
          this.errors.push(label);
          this.errors["terms"] = label;
        }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },    
    async saveTos() {
      this.loading = true;
      let uri = '/api/vendor/tos';
      await this.$http.post(uri, {tos: this.tos})
          .then((response) => {
            this.$toast.success("Saved successfully.", "Success");
            this.tos = response.data.tos;
          }).finally(() => this.loading = false);
    },
    getTos() {
      let uri = '/api/vendor/tos';
      this.$http.get(uri)
          .then(({data}) => {
            this.tos = data.data.tos
          });
    },    
  }
}
</script>
