var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-mt-4"},[_c('h2',{staticClass:"tw-mb-4 tw-font-semibold tw-text-2xl"},[_vm._v(_vm._s(_vm.$t('vendor.toc')))]),_c('h4',{staticClass:"tw-mb-2"},[_vm._v(" Please upload your terms and conditions as well as any other rules or policies that apply to Customers who book your Vendor Offerings as they are required to use our Platform. If you need help preparing this information, please use an appropriate third-party website or contact your attorney. For more information regarding Mango & Lola's Terms of Use, please "),_c('router-link',{staticClass:"tw-text-black tw-font-semibold",attrs:{"href":"_blank","to":{ name: 'terms.and.conditions'}}},[_vm._v("Click Here")]),_vm._v(". ")],1),_c('v-form',{ref:"tos-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('div',{staticClass:"tw-mt-6"},[_c('ml-error-msg',{attrs:{"msg":_vm.errors['terms']}}),_c('editor',{attrs:{"id":"terms","name":"terms","init":{
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                      }},model:{value:(_vm.tos),callback:function ($$v) {_vm.tos=$$v},expression:"tos"}}),_c('ml-error-msg',{attrs:{"msg":_vm.errors['terms']}})],1),_c('div',{staticClass:"tw-mt-6"},[_c('button',{staticClass:"tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black",attrs:{"type":"submit"}},[_vm._v(" Save ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }